<template>
    <b-modal 
      id="modal-payment-detail"
      ref="modal"
      title="Rincian Pembayaran"
      @ok="handleOK"
      @hidden="resetModal"
      centered>
      <p>Berikut adalah rincian total pembayaran yang harus dibayarkan:</p>
        <table style="table-layout:auto; width:100%; text-align:center">
            <tr>
                <th>Nama</th>
                <th>No AIMS</th>
                <th>Badan</th>
            </tr>
            <tr>
                <td style="vertical-align: top">{{detail.fullName !== undefined ? detail.fullName.split(' ').slice(0,2).join(' ') : ''}}</td>
                <td style="vertical-align: top">{{$store.getters.dataUser.noAims}}</td>
                <td style="vertical-align: top">{{$store.getters.dataUser.tanzeem}}</td>
            </tr>
        </table>
        <br />
        <v-divider />
        <table style="table-layout:auto; width:100%">
            <tr>
                <th>Jenis Simpanan</th>
                <th style="text-align:right">Total</th>
            </tr>
            <tr>
                <td>Simpanan Pokok</td>
                <td style="text-align:right">Rp{{this.$root.currencyFormat(detail.amountPrincipal)}}</td>
            </tr>
            <tr>
                <td>Simpanan Wajib</td>
                <td style="text-align:right">Rp{{this.$root.currencyFormat(detail.amountMandatory)}}</td>
            </tr>
            <tr>
                <td class="pt-5" style="font-size:25px; color:#0091EA">TOTAL</td>
                <td class="pt-5" style="text-align:right; font-size:25px; color:#0091EA">Rp{{this.$root.currencyFormat(parseInt(detail.amountMandatory) + parseInt(detail.amountPrincipal))}}</td>
            </tr>
        </table>
        <p v-if="message !== null" style="color:red" class="mt-5">{{ message }}</p>
        <template #modal-footer>
            <b-button
                variant="danger"
                size="md"
                class="float-right"
                @click="onCancelModal"
                style="color:#fff">Batal</b-button>
            <b-button
                variant="primary"
                size="md"
                class="float-right"
                @click="onSubmitModal"
                style="color:#fff">
                <span v-show="!isLoad">Kirim Pembayaran</span>
                <b-spinner v-show="isLoad" small></b-spinner>
                </b-button>
                
      </template>
      </b-modal>
</template>
<script>
import { mapActions } from "vuex"

export default {
    props: ['data'],
    data() {
        return {
           detail: this.data,
           isLoad: false,
           message: null
        }
    },
    computed: {
    },
    watch:{
        data: function(value){
            this.detail = []
            for (let i = 0; i < value.length; i++) {
                this.detail = value[i]   
                break
            }
            // console.log(JSON.stringify(this.detail))
        }
    },
    methods: {
        ...mapActions(["CreateMember"]),
        show() {
        this.$refs.modal.show()
        },
        handleOK(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.onSubmitModal()
        },
        async onSubmitModal() {
            this.isLoad = true
            // console.log(this.detail.emailAddress)
            const User = new FormData()
            User.append("ktpFile", this.detail.uploadID)
            User.append("request", JSON.stringify({
                address: {
                    address: this.detail.addressByID.address,
                    kabupaten: this.detail.addressByID.city,
                    kecamatan: this.detail.addressByID.district,
                    kelurahan: this.detail.addressByID.subDistrict,
                    provinsi: this.detail.addressByID.province,
                    rt: this.detail.addressByID.rt,
                    rw: this.detail.addressByID.rw
                },
                addressResidence: {
                    address: this.detail.addressByDomicile.address,
                    kabupaten: this.detail.addressByDomicile.city,
                    kecamatan: this.detail.addressByDomicile.district,
                    kelurahan: this.detail.addressByDomicile.subDistrict,
                    provinsi: this.detail.addressByDomicile.province,
                    rt: this.detail.addressByDomicile.rt,
                    rw: this.detail.addressByDomicile.rw
                },
                amountMandatorySaving: this.detail.amountMandatory,
                amountPrincipalSaving: this.detail.amountPrincipal,
                bankName: this.detail.bankName.replace('Bank ',''),
                branch: this.$store.getters.dataUser.branch,
                dateOfBirth: this.detail.dob,
                email: this.detail.emailAddress,
                fullName: this.detail.fullName,
                gender: this.detail.gender,
                identityNumber: this.detail.nomorID,
                identityType: this.detail.labelID,
                noAims: this.$store.getters.dataUser.noAims,
                phone: this.detail.phoneNo,
                placeOfBirth: this.detail.pob,
                tanzeem: this.$store.getters.dataUser.tanzeem
            }))

            await this.CreateMember(User).then(res => {
                var link = process.env.VUE_APP_API_BASE_URL + this.$root.api.get.invoice
                link = link + '/invoice&&' + res.detail.paymentId + '&kbufu&' + res.detail.invoiceNo + '&kbufu&' + 201

                if(res.code === 201){
                    this.$router.push({path: '/confirmation', query: {status: 1}})
                    window.open(link,'_blank')
                }else if(res.status === 'Success' && res.data.memberNo === null){
                    this.message = res.message
                }
                else{
                    this.$router.push({path: '/confirmation', query: {status: 2}})
                }
            }).catch(err => {
                console.log(err)
            })
            this.isLoad = false
        },
        onCancelModal(){
           this.detail = []
           this.$bvModal.hide('modal-payment-detail')
        },
        resetModal() {
            this.detail = []
        }
    }
}
</script>
<template>
    <b-modal 
      id="modal-confirm-payment"
      ref="modal"
      title="Pilih Pembayaran"
      @ok="handleOK"
      @show="resetModal"
      @hidden="resetModal"
      centered>
      <span>Tagihan akan dikirim ke alamat e-mail :</span>
      <p :style="{fontWeight:'bold'}">{{ form.emailAddress }}</p>
        <v-select
          v-model="selectBank"
          :items="listBank"
          label="Pilih Bank"
          solo
          :error-messages="errorMsg"
          required
        >
            <template v-slot:selection="{ item }">
                <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                <span>{{ item.bankName }}</span>
            </template>
            <template v-slot:item="{ item }">
                <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                <span>{{ item.bankName }}</span>
            </template>
        </v-select>
        <template #modal-footer>
            <b-button
                variant="primary"
                size="md"
                class="float-right"
                @click="onSubmitModal"
                style="color:#fff">Rincian Pembayaran</b-button>
      </template>
      <PaymentDetail ref="paymentDetailModal" :data=data />
      </b-modal>
</template>
<script>
import {isEmpty} from '@/lib/util/utilities.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import PaymentDetail from './PaymentDetail.vue'

export default {
    mixins: [validationMixin],
    validations: {
        selectBank: {required}
    },
    components:{
        PaymentDetail
    },
    props: ['form'],
    data() {
        return {
            selectBank: '',
            listBank: [],
            data: []
        }
    },
    computed: {
        errorMsg() {
        const errors = []
            if (!this.$v.selectBank.$dirty) return errors
            if(this.selectBank.length==0) errors.push('Mohon pilih bank dahulu.')
            return errors
        },
    },
    methods: {
        show() {
        this.$refs.modal.show()
        },
        checkModalValidity() {
            this.$v.$touch()
            return !isEmpty(this.selectBank)
        },
        resetModal() {
            this.selectBank = ''
            this.data = []
        },
        handleOK(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.onSubmitModal()
        },
        onSubmitModal() {
        if (!this.checkModalValidity()) {
            return
        }
        // console.log('confirm ' + this.form.emailAddress)
        this.data = []
        this.data.push({
            fullName: this.form.fullName,
            nomorID: this.form.nomorID,
            phoneNo: this.form.phoneNo,
            emailAddress: this.form.emailAddress,
            pob: this.form.pob.name,
            dob: this.form.dob,
            gender: this.form.gender,
            addressByID: {
                rt: this.form.addressByID.rt,
                rw: this.form.addressByID.rw,
                province: this.form.addressByID.province.name,
                city: this.form.addressByID.city.name,
                subDistrict: this.form.addressByID.subDistrict.name,
                district: this.form.addressByID.district.name,
                postCode: this.form.addressByID.postCode,
                address: this.form.addressByID.address,
            },
            addressByDomicile: {
                rt: this.form.addressByDomicile.rt,
                rw: this.form.addressByDomicile.rw,
                province: this.form.addressByDomicile.province.name,
                city: this.form.addressByDomicile.city.name,
                subDistrict: this.form.addressByDomicile.subDistrict.name,
                district: this.form.addressByDomicile.district.name,
                postCode: this.form.addressByDomicile.postCode,
                address: this.form.addressByDomicile.address,
            },
            amountPrincipal: this.form.amountPrincipal,
            amountMandatory: this.form.amountMandatory,
            uploadID: this.form.uploadID,
            labelID: this.form.labelID,
            bankCode: this.selectBank.bankCode,
            bankName: this.selectBank.bankName
        })
        this.$refs.paymentDetailModal.show()
        }
    },
    mounted() {
        this.$root.api.sentAPI(
            {
                method: 'get',
                api: this.$root.api.get.bank,
            }).then((res) => {
                this.listBank = res.data
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
    }
}
</script>
<template>
    <b-container fluid :style="{ padding: '0px 20px'}">
      <b-row class="justify-content-center row">
        <b-card title="FORMULIR PENDAFTARAN ANGGOTA KOPERASI" sub-title="Isilah formulir dibawah ini dengan data yang sebenar - benar nya" class="card" :style="{marginBottom:'20px'}"></b-card>
      </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Nama Sesuai Identitas *" class="card card-form">
              <v-text-field
                class="input"
                label="Masukan nama pendaftar"
                :error-messages="fullNameError"
                v-model="form.fullName"
                required
                @input="$v.fullName.$touch()"
                @blur="$v.fullName.$touch()"
              ></v-text-field>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Nomor Identitas *" class="card card-form">
              <b-row>
                <b-col md="6">
                  <v-text-field
                    class="input"
                    :label="form.labelID"
                    :error-messages="nomorIDError"
                    v-model="form.nomorID"
                    required
                    :maxlength="maxLengthID"
                    @input="$v.nomorID.$touch()"
                    @blur="$v.nomorID.$touch()"
                  ></v-text-field>
                </b-col>
                <b-col md="6">
                  <v-file-input
                    class="input"
                    accept="image/png, image/jpeg, image/bmp"
                    :placeholder="`Upload ` + form.labelID"
                    :error-messages="uploadError"
                    prepend-icon="mdi-camera"
                    required
                    v-model="form.uploadID"
                    @input="$v.uploadID.$touch()"
                    @blur="$v.uploadID.$touch()"
                  ></v-file-input>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <!-- <b-row class="justify-content-center row">
            <b-card :title="'Upload ' + form.labelID+ ' *' " class="card card-form">
              <v-file-input
                class="input"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Upload"
                :error-messages="uploadError"
                prepend-icon="mdi-camera"
                required
                v-model="form.uploadID"
                @input="$v.uploadID.$touch()"
                @blur="$v.uploadID.$touch()"
              ></v-file-input>
            </b-card>
          </b-row> -->
          <b-row class="justify-content-center row">
            <b-card title="Nomor Handphone / Telp *" class="card card-form">
              <v-text-field
                class="input"
                label="cth 08xxxxxxxxx"
                :error-messages="phoneError"
                v-model="form.phoneNo"
                required
                type="number"
                @input="$v.phoneNo.$touch()"
                @blur="$v.phoneNo.$touch()"
              ></v-text-field>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Alamat Email *" class="card card-form">
              <v-text-field
                class="input"
                label="Masukan email aktif"
                :error-messages="emailAddressError"
                v-model="form.emailAddress"
                required
                type="email"
                @input="$v.emailAddress.$touch()"
                @blur="$v.emailAddress.$touch()"
              ></v-text-field>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Tempat / Tgl lahir *" class="card card-form">
              <b-row>
                <b-col md="3">
                    <v-combobox
                      v-model="form.pob"
                      :items="form.listCityAll"
                      :error-messages="pobError"
                      item-text="name"
                      item-value="code"
                      label="Tempat Lahir"
                      @input="$v.pob.$touch()"
                      @blur="$v.pob.$touch()"
                    ></v-combobox>
                </b-col>
                <b-col md="9">
                    <v-text-field
                      label="Tanggal Lahir"
                      :error-messages="dobError"
                      v-model="form.dob"
                      required
                      type="date"
                      @input="$v.dob.$touch()"
                      @blur="$v.dob.$touch()"
                    ></v-text-field>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Jenis Kelamin *" class="card card-form">
              <v-radio-group
                class="input"
                required
                :error-messages="genderError"
                v-model="form.gender"
                row
              >
                <v-radio
                  label="Laki-Laki"
                  value="L"
                ></v-radio>
                <v-radio
                  label="Perempuan"
                  value="P"
                ></v-radio>
              </v-radio-group>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Alamat Sesuai Identitas" class="card card-form">
              <b-row>
                <v-col md="1">
                    <v-text-field
                      label="RT"
                      v-model="form.addressByID.rt"
                      type="text"
                    ></v-text-field>
                </v-col>
                <v-col md="1">
                    <v-text-field
                      label="RW"
                      v-model="form.addressByID.rw"
                      type="text"
                    ></v-text-field>
                </v-col>
                <b-col md="3">
                  <v-combobox
                    v-model="form.addressByID.province"
                    :items="listProvince"
                    :error-messages="addressProvinceError"
                    required
                    item-text="name"
                    item-value="code"
                    label="Provinsi *"
                    @input="$v.addressByID.province.$touch()"
                    @blur="$v.addressByID.province.$touch()"
                  ></v-combobox>
              </b-col>
              <b-col md="3">
                  <v-combobox
                    v-model="form.addressByID.city"
                    :items="form.addressByID.listCity"
                    :error-messages="addressCityError"
                    required
                    item-text="name"
                    item-value="code"
                    label="Kab/Kota *"
                    @input="$v.addressByID.city.$touch()"
                    @blur="$v.addressByID.city.$touch()"
                  ></v-combobox>
              </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByID.district"
                      :items="form.addressByID.listDistrict"
                      :error-messages="addressDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kecamatan *"
                      @input="$v.addressByID.district.$touch()"
                      @blur="$v.addressByID.district.$touch()"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByID.subDistrict"
                      :items="form.addressByID.listSubDistrict"
                      :error-messages="addressSubDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kelurahan *"
                      @input="$v.addressByID.subDistrict.$touch()"
                      @blur="$v.addressByID.subDistrict.$touch()"
                    ></v-combobox>
                </b-col>
                <b-col md="9">
                    <v-text-field
                      label="Masukan nama jalan / no rumah / nama(kampung / komplek)"
                      v-model="form.addressByID.address"
                      type="text"
                      maxlength="60"
                    ></v-text-field>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Alamat Sesuai Domisili " class="card card-form">
              <b-row>
                <b-col md="12">
                  <b-form-checkbox
                    id="cb-address"
                    v-model="form.cbAddress"
                    name="cb-address"
                    value="1"
                    unchecked-value="0"
                    @change="checkFormAddress()"
                  >
                    Alamat sama dengan identitas
                  </b-form-checkbox>
                </b-col>
                <v-col md="1">
                    <v-text-field
                      label="RT"
                      v-model="form.addressByDomicile.rt"
                      type="text"
                      :disabled="form.cbAddress==1"
                    ></v-text-field>
                </v-col>
                <v-col md="1">
                    <v-text-field
                      label="RW"
                      v-model="form.addressByDomicile.rw"
                      type="text"
                      :disabled="form.cbAddress==1"
                    ></v-text-field>
                </v-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.province"
                      :items="listProvince"
                      :error-messages="addressDomicileProvinceError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Provinsi *"
                      @input="$v.addressByDomicile.province.$touch()"
                      @blur="$v.addressByDomicile.province.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.city"
                      :items="form.addressByDomicile.listCity"
                      :error-messages="addressDomicileCityError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kab/Kota *"
                      @input="$v.addressByDomicile.city.$touch()"
                      @blur="$v.addressByDomicile.city.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.district"
                      :items="form.addressByDomicile.listDistrict"
                      :error-messages="addressDomicileDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kecamatan *"
                      @input="$v.addressByDomicile.district.$touch()"
                      @blur="$v.addressByDomicile.district.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="3">
                    <v-combobox
                      v-model="form.addressByDomicile.subDistrict"
                      :items="form.addressByDomicile.listSubDistrict"
                      :error-messages="addressDomicileSubDistrictError"
                      required
                      item-text="name"
                      item-value="code"
                      label="Kelurahan *"
                      @input="$v.addressByDomicile.subDistrict.$touch()"
                      @blur="$v.addressByDomicile.subDistrict.$touch()"
                      :disabled="form.cbAddress==1"
                    ></v-combobox>
                </b-col>
                <b-col md="9">
                    <v-text-field
                      label="Masukan nama jalan / no rumah / nama(kampung / komplek)"
                      v-model="form.addressByDomicile.address"
                      type="text"
                      :disabled="form.cbAddress==1"
                      maxlength="60"
                    ></v-text-field>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Besaran Simpanan Pokok *" class="card card-form">
              <b-row>
                <b-col md="12">
                    <v-select
                      class="input"
                      v-model="form.amountPrincipal"
                      :items="listAmountPrincipal"
                      prefix="Rp"
                      :hint="'Rp'+$root.currencyFormat(form.amountPrincipal) + (form.amountPrincipal !== 0 ? ' (' + $root.terbilang(form.amountPrincipal) + ' rupiah)' : '')"
                      persistent-hint
                      :error-messages="amountPrincipalError"
                      label="Besaran pokok"
                    ></v-select>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card title="Besaran Simpanan Wajib *" class="card card-form">
              <v-text-field
                class="input"
                :label="setLabelAmountMandatory"
                :error-messages="amountMandatoryError"
                v-model="form.amountMandatory"
                required
                :hint="'Rp'+$root.currencyFormat(form.amountMandatory) + (form.amountMandatory !== '' ? ' (' + $root.terbilang(form.amountMandatory) + ' rupiah)' : '')"
                persistent-hint
                prefix="Rp"
                type="number"
                @input="$v.amountMandatory.$touch()"
                @blur="$v.amountMandatory.$touch()"
              ></v-text-field>
            </b-card>
          </b-row>
          <b-row class="justify-content-center row">
            <b-card class="card">
              <b-card-text>
                <b-form-checkbox
                  id="cb-policy"
                  v-model="form.cbPolicy"
                  name="cb-policy"
                  value="1"
                  unchecked-value="0"
                >
                  Saya menyetujui persyaratan dan ketentuan untuk menjalankan nilai-nilai koperasi
                </b-form-checkbox>
              </b-card-text>
            <Alert 
              border="left" 
              color="red lighten-2" 
              :show=validate.showMsg 
              message="Terdapat data yang belum sesuai, mohon periksa kembali formulir" />
            <v-btn :disabled='form.cbPolicy == 0' type="submit" color="primary" width="200" @click="showModalPay">Daftar</v-btn>
            </b-card>
          </b-row>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
    <ConfirmPayment ref="confirmPayModal" :form=form />
    </b-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { lowercaseFormat } from '@/lib/util/utilities.js'
import api from '@/lib/util/api.js'

import OverlayProgress from '@/components/OverlayProgress.vue'
import ConfirmPayment from '@/components/modal/ConfirmPayment.vue'
import Alert from '@/components/Alert.vue'



export default {
  name: 'Register',
  components: {
    OverlayProgress,
    Alert,
    ConfirmPayment
  },
  mixins: [validationMixin],
  validations: {
      fullName: {required},
      nomorID: {required},
      phoneNo: {required},
      emailAddress: {required},
      pob: {required},
      dob: {required},
      gender: {required},
      addressByID: {
        province: {required},
        city: {required},
        subDistrict: {required},
        district: {required}
      },
      addressByDomicile: {
        province: {required},
        city: {required},
        subDistrict: {required},
        district: {required}
      },
      amountPrincipal: {required},
      amountMandatory: {required},
      uploadID: {required}
    },
  data() {
    return {
      validate: {
        showMsg: false,
        fullName: false,
        nomorID: false,
        phoneNo: false,
        emailAddress: false,
        pob: false,
        dob: false,
        gender: false,
        addressByID: {
          province: false,
          city: false,
          subDistrict: false,
          district: false
        },
        addressByDomicile: {
          province: false,
          city: false,
          subDistrict: false,
          district: false
        },
        amountPrincipal: false,
        amountMandatory: false,
        uploadID: false
      },
      form: {
        fullName: '',
        nomorID: '',
        phoneNo: '',
        emailAddress: '',
        pob: '',
        dob: '',
        gender: '',
        listCityAll: [],
        addressByID: {
          rt: '',
          rw: '',
          province: '',
          city: '',
          subDistrict: '',
          district: '',
          postCode: '',
          address: '',
          listCity: [],
          listSubDistrict: [],
          listDistrict: [],
        },
        addressByDomicile: {
          rt: '',
          rw: '',
          province: '',
          city: '',
          subDistrict: '',
          district: '',
          postCode: '',
          address: '',
          listCity: [],
          listSubDistrict: [],
          listDistrict: [],
        },
        amountPrincipal: 0,
        amountMandatory: '',
        amountVolunteer: '',
        uploadID: null,
        cbPolicy: '0',
        cbAddress: '0',
        labelAmounMandatory: '0',
        labelID: 'KTP/SIM/PASSPORT',
      },
      listProvince: [],
      listAmountPrincipal: [],
      maxLengthID: 16
    }
  },
  computed: {
    nomorIDError() {
      const errors = []
        if (!this.$v.nomorID.$dirty) return errors
        if(this.form.nomorID.length==0) errors.push('Mohon masukan nomor identitas anda.')
        return errors
    },
    fullNameError() {
      const errors = []
        if (!this.$v.fullName.$dirty) return errors
        if(this.form.fullName.length==0) errors.push('Mohon masukan nama lengkap anda.')
        return errors
    },
    phoneError() {
      const errors = []
        if (!this.$v.phoneNo.$dirty) return errors
        if(this.form.phoneNo.length==0) errors.push('Mohon masukan nomor handphone/telp anda.')
        return errors
    },
    emailAddressError() {
      const errors = []
        if (!this.$v.emailAddress.$dirty) return errors
        if(this.form.emailAddress.length==0) errors.push('Mohon masukan alamat email anda.')
        if(this.form.emailAddress.length>0 && !this.validate.emailAddress) errors.push('Format email tidak sesuai.')
        return errors
    },
    pobError() {
      const errors = []
        if (!this.$v.pob.$dirty) return errors
        if(typeof this.form.pob !== typeof {} && this.form.pob.length>0) errors.push('Mohon masukan tempat sesuai pada pilihan.')
        if(this.form.pob.length==0) errors.push('Mohon masukan tempat lahir anda.')
        return errors
    },
    dobError() {
      const errors = []
        if (!this.$v.dob.$dirty) return errors
        if(this.form.dob.length==0) errors.push('Mohon masukan tanggal lahir anda.')
        return errors
    },
    genderError() {
      const errors = []
        if (!this.$v.gender.$dirty) return errors
        if(this.form.gender.length==0) errors.push('Mohon masukan jenis kelamin anda.')
        return errors
    },
    addressProvinceError() {
      const errors = []
        if (!this.$v.addressByID.province.$dirty) return errors
        if(typeof this.form.addressByID.province !== typeof {} && this.form.addressByID.province.length>0) 
          errors.push('Mohon masukan provinsi sesuai pada pilihan.')
        if(this.form.addressByID.province.length==0) errors.push('Mohon masukan alamat provinsi anda.')
        return errors
    },
    addressCityError() {
      const errors = []
        if (!this.$v.addressByID.city.$dirty) return errors
        if(typeof this.form.addressByID.city !== typeof {} && this.form.addressByID.city.length>0) 
          errors.push('Mohon masukan kab/kota sesuai pada pilihan.')
        if(this.form.addressByID.city.length==0) errors.push('Mohon masukan alamat kab/kota anda.')
        return errors
    },
    addressSubDistrictError() {
      const errors = []
        if (!this.$v.addressByID.subDistrict.$dirty) return errors
        if(typeof this.form.addressByID.subDistrict !== typeof {} && this.form.addressByID.subDistrict.length>0) 
          errors.push('Mohon masukan kelurahan sesuai pada pilihan.')
        if(this.form.addressByID.subDistrict.length==0) errors.push('Mohon masukan alamat kelurahan anda.')
        return errors
    },
    addressDistrictError() {
      const errors = []
        if (!this.$v.addressByID.district.$dirty) return errors
        if(typeof this.form.addressByID.district !== typeof {} && this.form.addressByID.district.length>0) 
          errors.push('Mohon masukan kecamatan sesuai pada pilihan.')
        if(this.form.addressByID.district.length==0) errors.push('Mohon masukan alamat kecamatan anda.')
        return errors
    },
    addressDomicileProvinceError() {
      const errors = []
        if (!this.$v.addressByDomicile.province.$dirty) return errors
        if(typeof this.form.addressByDomicile.province !== typeof {} && this.form.addressByDomicile.province.length>0) 
          errors.push('Mohon masukan provinsi sesuai pada pilihan.')
        if(this.form.addressByDomicile.province.length==0) errors.push('Mohon masukan alamat provinsi anda.')
        return errors
    },
    addressDomicileCityError() {
      const errors = []
        if (!this.$v.addressByDomicile.city.$dirty) return errors
        if(typeof this.form.addressByDomicile.city !== typeof {} && this.form.addressByDomicile.city.length>0) 
          errors.push('Mohon masukan kab/kota sesuai pada pilihan.')
        if(this.form.addressByDomicile.city.length==0) errors.push('Mohon masukan alamat kab/kota anda.')
        return errors
    },
    addressDomicileSubDistrictError() {
      const errors = []
        if (!this.$v.addressByDomicile.subDistrict.$dirty) return errors
        if(typeof this.form.addressByDomicile.subDistrict !== typeof {} && this.form.addressByDomicile.subDistrict.length>0) 
          errors.push('Mohon masukan kelurahan sesuai pada pilihan.')
        if(this.form.addressByDomicile.subDistrict.length==0) errors.push('Mohon masukan alamat kelurahan anda.')
        return errors
    },
    addressDomicileDistrictError() {
      const errors = []
        if (!this.$v.addressByDomicile.district.$dirty) return errors
        if(typeof this.form.addressByDomicile.district !== typeof {} && this.form.addressByDomicile.district.length>0) 
          errors.push('Mohon masukan kecamatan sesuai pada pilihan.')
        if(this.form.addressByDomicile.district.length==0) errors.push('Mohon masukan alamat kecamatan anda.')
        return errors
    },
    amountPrincipalError() {
      const errors = []
        if (!this.$v.amountPrincipal.$dirty) return errors
        if(this.form.amountPrincipal==0) errors.push('Mohon masukan besaran pokok anda.')
        return errors
    },
    amountMandatoryError() {
      const errors = []
        if (!this.$v.amountMandatory.$dirty) return errors
        if(this.form.amountMandatory.length>0 && this.form.amountMandatory < this.form.labelAmounMandatory) errors.push('Simpanan wajib minimal sebesar Rp.'+this.form.labelAmounMandatory)
        if(this.form.amountMandatory.length==0) errors.push('Mohon masukan besaran wajib anda.')
        return errors
    },
    uploadError() {
      const errors = []
        if (!this.$v.uploadID.$dirty) return errors
        if(this.form.uploadID==null) errors.push('Mohon upload '+this.form.labelID+' anda.')
        return errors
    },
    setLabelAmountMandatory() {
      return 'Min Rp' + this.$root.currencyFormat(this.form.labelAmounMandatory)
    },
  },
  watch: {
    'form.nomorID': function() {
      if (this.form.nomorID.length>0) {
        this.validate.showMsg = false 
        this.validate.nomorID = true
        if(this.form.nomorID.charAt(0).match(/\d+/g) === null) {
          this.form.labelID = 'PASSPORT'
          this.maxLengthID = 8
        }else if(this.form.nomorID.length > 8 && this.form.nomorID.length <= 12) {
          this.form.labelID = 'SIM'
        }else if(this.form.nomorID.length > 12) {
          this.form.labelID = 'KTP'
          this.maxLengthID = 16
        }else{
          this.maxLengthID = 16
        }
      }
      else {
        this.validate.nomorID = false
        this.form.labelID = 'KTP/SIM/PASSPORT'
        this.maxLengthID = 16
      }
    },
    'form.fullName': function() {
      if (this.form.fullName.length>0) {
        this.validate.showMsg = false 
        this.validate.fullName = true
      }
      else this.validate.fullName = false
    },
    'form.phoneNo': function() {
      if (this.form.phoneNo.length>0) {
        this.validate.showMsg = false 
        this.validate.phoneNo = true
      }
      else this.validate.phoneNo = false
    },
    'form.emailAddress': function() {
      this.form.emailAddress = lowercaseFormat(this.form.emailAddress)
      if (this.form.emailAddress.length>0) {
        this.validate.showMsg = false 
        this.validate.emailAddress = true
      }
      else this.validate.emailAddress = false
    },
    'form.pob': function() {
      if(typeof this.form.pob !== typeof {} && this.form.pob.length>0) this.validate.pob = false
      else if (typeof this.form.pob === typeof {} && this.form.pob.name.length>0 ) {
        this.validate.showMsg = false 
        this.validate.pob = true
      }
      else this.validate.pob = false
    },
    'form.dob': function() {
      if (this.form.dob.length>0) {
        this.validate.showMsg = false 
        this.validate.dob = true
      }
      else this.validate.dob = false
    },
    'form.gender': function() {
      if (this.form.gender.length>0) {
        this.validate.showMsg = false 
        this.validate.gender = true
      }
      else this.validate.gender = false
    },
    'form.addressByID.province': function() {
      this.getCity(this.form.addressByID.province.code, true)
      this.form.addressByID.city = ''

      if(typeof this.form.addressByID.province !== typeof {} && this.form.addressByID.province.length>0) 
        this.validate.addressByID.province = false
      else if (typeof this.form.addressByID.province === typeof {} && this.form.addressByID.province.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.province = true
      }
      else this.validate.addressByID.province = false
    },
    'form.addressByID.city': function() {
      this.getDistrict(this.form.addressByID.city.code, true)
      this.form.addressByID.district = ''

      if(typeof this.form.addressByID.city !== typeof {} && this.form.addressByID.city.length>0) 
        this.validate.addressByID.city = false
      else if (typeof this.form.addressByID.city === typeof {} && this.form.addressByID.city.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.city = true
      }
      else this.validate.addressByID.city = false
    },
    'form.addressByID.subDistrict': function() {
      if(typeof this.form.addressByID.subDistrict !== typeof {} && this.form.addressByID.subDistrict.length>0) 
        this.validate.addressByID.subDistrict = false
      else if (typeof this.form.addressByID.subDistrict === typeof {} && this.form.addressByID.subDistrict.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.subDistrict = true
      }
      else this.validate.addressByID.subDistrict = false
    },
    'form.addressByID.district': function() {
      this.getSubDistrict(this.form.addressByID.district.code, true)
      this.form.addressByID.subDistrict = ''

      if(typeof this.form.addressByID.district !== typeof {} && this.form.addressByID.district.length>0) 
        this.validate.addressByID.district = false
      else if (typeof this.form.addressByID.district === typeof {} && this.form.addressByID.district.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByID.district = true
      }
      else this.validate.addressByID.district = false
    },
    'form.addressByDomicile.province': function() {
      this.getCity(this.form.addressByDomicile.province.code, false)
      if(this.form.cbAddress == 0) this.form.addressByDomicile.city = ''

      if(typeof this.form.addressByDomicile.province !== typeof {} && this.form.addressByDomicile.province.length>0) 
        this.validate.addressByDomicile.province = false
      else if (typeof this.form.addressByDomicile.province === typeof {} && this.form.addressByDomicile.province.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.province = true
      }
      else this.validate.addressByDomicile.province = false
    },
    'form.addressByDomicile.city': function() {
      this.getDistrict(this.form.addressByDomicile.city.code)
      if(this.form.cbAddress == 0) this.form.addressByDomicile.district = ''

      if(typeof this.form.addressByDomicile.city !== typeof {} && this.form.addressByDomicile.city.length>0) 
        this.validate.addressByDomicile.city = false
      else if (typeof this.form.addressByDomicile.city === typeof {} && this.form.addressByDomicile.city.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.city = true
      }
      else this.validate.addressByDomicile.city = false
    },
    'form.addressByDomicile.subDistrict': function() {
      if(typeof this.form.addressByDomicile.subDistrict !== typeof {} && this.form.addressByDomicile.subDistrict.length>0) 
        this.validate.addressByDomicile.subDistrict = false
      else if (typeof this.form.addressByDomicile.subDistrict === typeof {} && this.form.addressByDomicile.subDistrict.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.subDistrict = true
      }
      else this.validate.addressByDomicile.subDistrict = false
    },
    'form.addressByDomicile.district': function() {
      this.getSubDistrict(this.form.addressByDomicile.district.code, false)
      if(this.form.cbAddress == 0) this.form.addressByDomicile.subDistrict = ''

      if(typeof this.form.addressByDomicile.district !== typeof {} && this.form.addressByDomicile.district.length>0) 
        this.validate.addressByDomicile.district = false
      else if (typeof this.form.addressByDomicile.district === typeof {} && this.form.addressByDomicile.district.name.length>0) {
        this.validate.showMsg = false 
        this.validate.addressByDomicile.district = true
      }
      else this.validate.addressByDomicile.district = false
    },
    'form.amountPrincipal': function() {
      if (this.form.amountPrincipal !== 0) {
        this.validate.showMsg = false 
        this.validate.amountPrincipal = true
      }
      else this.validate.amountPrincipal = false
    },
    'form.amountMandatory': function() {
      if (this.form.amountMandatory.length>0) {
        this.validate.showMsg = false 
        this.validate.amountMandatory = true
        if(this.form.amountMandatory < this.form.labelAmounMandatory) this.validate.amountMandatory = false
      }
      else this.validate.amountMandatory = false
    },
    'form.uploadID': function() {
      if (this.form.uploadID!=null) {
        this.validate.showMsg = false 
        this.validate.uploadID = true
      }
      else this.validate.uploadID = false
    }
  },
  methods: {
    checkFormAddress() {
      if (this.form.cbAddress==1) {
        this.form.addressByDomicile.rt=this.form.addressByID.rt
        this.form.addressByDomicile.rw=this.form.addressByID.rw
        this.form.addressByDomicile.province=this.form.addressByID.province
        this.form.addressByDomicile.city=this.form.addressByID.city
        this.form.addressByDomicile.subDistrict=this.form.addressByID.subDistrict
        this.form.addressByDomicile.district=this.form.addressByID.district
        this.form.addressByDomicile.postCode=this.form.addressByID.postCode
        this.form.addressByDomicile.address=this.form.addressByID.address
      }
      else{
        this.form.addressByDomicile.rt=''
        this.form.addressByDomicile.rw=''
        this.form.addressByDomicile.province=''
        this.form.addressByDomicile.city=''
        this.form.addressByDomicile.subDistrict=''
        this.form.addressByDomicile.district=''
        this.form.addressByDomicile.postCode=''
        this.form.addressByDomicile.address=''
      }
    },
    checkValid() {
      if(this.validate.emailAddress && !this.$root.validateEmail(this.form.emailAddress))
          this.validate.emailAddress = false
      if (this.validate.nomorID && 
          this.validate.fullName && 
          this.validate.phoneNo && 
          this.validate.emailAddress &&
          this.validate.pob && 
          this.validate.dob && 
          this.validate.gender && 
          this.validate.addressByID.province && 
          this.validate.addressByID.city && 
          this.validate.addressByID.subDistrict && 
          this.validate.addressByID.district && 
          this.validate.addressByDomicile.province && 
          this.validate.addressByDomicile.city && 
          this.validate.addressByDomicile.subDistrict && 
          this.validate.addressByDomicile.district && 
          this.validate.amountPrincipal && 
          this.validate.amountMandatory && 
          this.validate.uploadID ) return true 
      else {
        this.validate.showMsg = true 
        return false
      }
    },
    showModalPay() {
      this.$v.$touch()
      if(this.checkValid()) {
        this.$refs.confirmPayModal.show()
      }
    },
    async getCity(provinceID, isByID) {
      // get city
      await api.sentAPI(
      {
        method: 'get',
        api: api.get.city + provinceID
      }).then(res => {
        if(isByID){
          this.form.addressByID.listCity = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listCity.push({
              code: res.data[i].kabKotId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }else{
          this.form.addressByDomicile.listCity = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listCity.push({
              code: res.data[i].kabKotId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getDistrict(cityID, isByID) {
      // get district
      await api.sentAPI(
      {
        method: 'get',
        api: api.get.district + cityID
      }).then(res => {
        if(isByID) {
          this.form.addressByID.listDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listDistrict.push({
              code: res.data[i].kecamatanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }else{
          this.form.addressByDomicile.listDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listDistrict.push({
              code: res.data[i].kecamatanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getSubDistrict(districtID, isByID) {
      // get sub district
      await api.sentAPI(
      {
        method: 'get',
        api: api.get.subDistrict + districtID
      }).then(res => {
        if(isByID){
          this.form.addressByID.listSubDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByID.listSubDistrict.push({
              code: res.data[i].kelurahanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }else{
          this.form.addressByDomicile.listSubDistrict = []
          for (let i = 0; i < res.data.length; i++) {
            this.form.addressByDomicile.listSubDistrict.push({
              code: res.data[i].kelurahanId,
              name: this.$root.capitalFirstChart(res.data[i].nama)
            }) 
          }
        }
          
      })
      .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
      })
    }
  },
  async mounted() {
    // get province
    await api.sentAPI(
    {
      method: 'get',
      api: api.get.province
    }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          this.listProvince.push({
            code: res.data[i].provinsiId,
            name: this.$root.capitalFirstChart(res.data[i].nama)
          }) 
        }
    })
    .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
    })

    // get all city
    await api.sentAPI(
    {
      method: 'get',
      api: api.get.cityAll
    }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          this.form.listCityAll.push({
            code: res.data[i].kabKotId,
            name: this.$root.capitalFirstChart(this.$root.capitalFirstChart(res.data[i].nama))
          }) 
        }
    })
    .catch((err) => {
        console.log(err)
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
    })

    // get list saving
    await api.sentAPI(
    {
      method: 'get',
      api: api.get.simpananList
    }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if(res.data[i].simpananCode === 'SP-01'){
            for (let j = 0; j < res.data[i].simpananAmount.length; j++) {
              this.listAmountPrincipal.push(res.data[i].simpananAmount[j]) 
            }
          }
          if(res.data[i].simpananCode === 'SW-01')
            this.form.labelAmounMandatory = res.data[i].simpananAmount[0]
        }
    })
    .catch((err) => {
        console.log(err)
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
    })

    if(!this.$root.isError) this.$root.overlay = false
    
  },
}
</script>
<style scoped>
.row{
  margin-bottom: 10px;
}
.card {
  width: 925px;
  text-align: left;
}
.card-form{
  min-height: 170px;
}
.input{
  margin-top: 30px;
}
</style>
